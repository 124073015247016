<template transition="scale-transition">
  <v-container id="status_offers" fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" >
        <DataTable
          ref="Confirmed"
          :headers="headers"
          :name="$t('components.layout.sidebar.assets')"
          :title="$t('components.layout.sidebar.assets')"
          :url="url"
          actionsOn
          :searchP="searchField"
          updateOn
          :height="650"
          :urlCount="urlCount"
          id="status_offers_table"
        >

        <template
              v-for="header in headers"
              v-slot:[`header.${header.value}`]
              #activator="{ on, attrs }"
            >
            <div class="header-item">
              <div class="header-text">
                <span class="header-title">{{ header.text }}</span>
              </div>
            </div>
          </template>

          <template #[`item.status.amount`]="{ item }">
            {{ formatMoney(item.status.amount, item.currency) }}
          </template>
          <template #[`item.options`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="onOpenInfoOffer(item)"
                >
                  <v-icon small> fa-solid fa-circle-info </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.views.trip.active.card.detail") }}</span>
            </v-tooltip>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogInfoOffer" width="900px" persistent>
      <ModalDetails
        :idMonitoringOrder="idMonitoringOrder"
        :type="type"
        @closeForm="onCloseInfoOffer"
        :key="index"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      idMonitoringOrder: 0,
      index: 0,
      dialogInfoOffer: false,
      type: 0,
    };
  },
  components: {
    ModalDetails: () =>
      import(
        /* webpackChunkName: "ModalDetails" */ "@/components/views/Trip/Active/modals/ModalDetails.vue"
      ),
  },
  name: "Confirmed",
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    unlockUser() {
      return JSON.parse(this.user);
    },
    typeUser() {
      return this.unlockUser.user.typeUser;
    },
    url() {
      return `/Travels/actives`;
    },
    urlCount() {
      return `/Travels/TotalRecordsActives`;
    },
    searchField() {
      return this.search;
    },
    headers() {
      return [
        {
          text: this.$t("components.views.trip.active.table.trip_no"),
          class: "text-center",
          align: "center",
          value: "tripNumber",
        },
        {
          text: this.$t("components.views.trip.active.table.amount"),
          class: "text-center",
          align: "center",
          value: "amount",
        },
        {
          text: this.$t("components.views.trip.active.table.accepted_value"),
          class: "text-center",
          align: "center",
          value: "amountConfirmed",
        },
        {
          text: this.$t("components.views.trip.active.table.type_operation"),
          class: "text-center",
          align: "center",
          value: "nameTypeOperation",
        },
        {
          text: this.$t("components.views.trip.active.table.origin_city"),
          class: "text-center",
          align: "center",
          value: "origin.city",
        },
        {
          text: this.$t("components.views.trip.active.table.origin_address"),
          class: "text-center",
          align: "center",
          value: "origin.address",
        },
        {
          text: this.$t("components.views.trip.active.table.origin_date"),
          class: "text-center",
          align: "center",
          value: "origin.schedule_date",
        },
        {
          text: this.$t("components.views.trip.active.table.destiny_city"),
          class: "text-center",
          align: "center",
          value: "destiny.city",
        },
        {
          text: this.$t("components.views.trip.active.table.destiny_address"),
          class: "text-center",
          align: "center",
          value: "destiny.address",
        },
        {
          text: this.$t("components.views.trip.active.table.destiny_date"),
          class: "text-center",
          align: "center",
          value: "destiny.schedule_date",
        },
        {
          text: this.$t("components.views.trip.active.table.assigned_transport"),
          class: "text-center",
          align: "center",
          value: "nameTransportCompany",
        },
        {
          text: this.$t("components.views.trip.active.table.assigned_driver"),
          class: "text-center",
          align: "center",
          value: "driver.name",
        },
        {
          text: this.$t("components.views.trip.active.table.assigned_vehicle"),
          class: "text-center",
          align: "center",
          value: "licensePlate",
        },
        {
          text: this.$t("components.views.trip.active.table.number_stops"),
          class: "text-center",
          align: "center",
          value: "stopsCount",
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.$t('components.layout.sidebar.assets'),
    };
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    onRealoadTable() {
      this.$refs.Confirmed.loadData();
    },

    onOpenInfoOffer(item) {
      this.dialogInfoOffer = true;
      this.$nextTick(() => {
        this.index += 1;

        this.idMonitoringOrder = item?.idMonitoringOrder || 0;
        this.type = item.type;
      });
    },
    onCloseInfoOffer() {
      this.dialogInfoOffer = false;
      this.$nextTick(() => {
        this.idMonitoringOrder = 0;
      });
    },
  },
};
</script>



<style scoped>
@import './TableStyle.scss';
</style>

<template transition="scale-transition" >
  <v-container id="status_offers" fluid fill-height>
    <v-row align="center" justify="center" no-gutters style="margin-top: 20px;">
      <v-col cols="12">
        <DataTable
          ref="NoAssignTable"
          :headers="headers"
          :name="$t('components.layout.sidebar.not_offer_assigned')"
          :url="url"
          updateOn
          actionsOn
          filtersOn
          massiveOfferOn
          show-select
          checkbox-color="tercery"
          :searchP="searchField"
          :height="650"
          :urlCount="urlCount"
          id="status_offers_table"
          v-model="selected"
          item-key="idMonitoringOrder"
        >
        <template v-slot:top>
            <v-btn
                  color="#5C48F7"
                  v-if="selected.length > 0"
                  class="btn-massive-offer qa-0 ma-0"
                  @click="onOpenModalOffersMassive()"
                >
                 Oferta Masiva
            </v-btn>
          </template>

          <template #[`item.status.amount`]="{ item }">
            {{ formatMoney(item.amount, item.currency) }}
          </template>

          <template #[`item.information.origin.schedule_date`]="{ item }">
            {{
              item.origin.schedule_date
            }}
          </template>

          <template #[`item.information.destiny.schedule_date`]="{ item }">
            {{
              item.destiny.schedule_date
            }}
          </template>

          <template #[`item.origin.address`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on" class="text-wrap text-column">
                 {{ item.origin?item.origin.address: "" }}
                </span>
              </template>
              <span>  {{ item.origin?item.origin.address: "" }} </span>
            </v-tooltip>
          </template>

          <template #[`item.destiny.address`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on" class="text-wrap text-column">
                 {{ item.destiny?item.destiny.address: "" }}
                </span>
              </template>
              <span>  {{ item.destiny?item.destiny.address: "" }} </span>
            </v-tooltip>
          </template>

          <template #[`item.options`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="onOpenModalOfferTrips(item)"
                >
                  <v-icon small> fa-solid fa-dollar-sign </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.views.trip.no_offer_assign.table.offer") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  color="red darken-2"
                  v-on="on"
                  @click="onOpenModalAssign(item)"
                >
                  <v-icon small> fa-solid fa-plus </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.views.trip.no_offer_assign.table.assign") }}</span>
            </v-tooltip>
          </template>
        </DataTable>

      </v-col>
    </v-row>
    <v-dialog v-model="dialogAssign" width="600px" persistent>
      <ModalAssign
        @closeForm="onCloseModal"
        @reloadPage="onRealoadTable"
        :idMonitoringOrder="idMonitoringOrder"
        :key="index"
      />
    </v-dialog>
    <v-dialog v-model="dialogOfferTrips" width="900px" scrollable persistent>
      <ModalOfferTrips
        @closeForm="onCloseModal"
        @reloadPage="onRealoadTable"
        :idMonitoringOrder="idMonitoringOrder"
        :key="index"
      />
    </v-dialog>
    <v-dialog v-model="dialogOffersMassive" width="900px" scrollable persistent>
      <ModalOffersMassive
        @closeForm="onCloseModal"
        @reloadPage="onRealoadTable"
        :selectedTrips="selected"
        :key="index"
      />
    </v-dialog>
  </v-container>
</template>



<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      idMonitoringOrder: 0,
      index: 0,
      dialogAssign: false,
      dialogOfferTrips: false,
      dialogOffersMassive: false,
      singleSelect: false,
      selected: [],
    };
  },
  components: {
    ModalAssign: () =>
      import(
        /* webpackChunkName: "ModalAssign" */ "@/components/views/Trip/NoOfferAssign/ModalAssign.vue"
      ),
    ModalOfferTrips: () =>
      import(
        /* webpackChunkName: "ModalOfferTrips" */ "@/components/views/Trip/NoOfferAssign/modals/modalofferstrips/ModalOfferTrips.vue"
      ),
    ModalOffersMassive: () =>
      import(
        /* webpackChunkName: "ModalOffersMassive" */ "@/components/views/Trip/NoOfferAssign/modals/modaloffersmassive/ModalOffersMassive.vue"
      ),
  },
  name: "NoOffersAssignTable",
  computed: {
    ...mapGetters({
      search: "search/search",
    }),
    url() {
      return `/Travels/unassigned`;
    },
    urlCount() {
      return `/Travels/TotalRecordsUnassigned`;
    },
    searchField() {
      return this.search;
    },
    headers() {
      return [
        {
          text: this.$t("components.views.trip.no_offer_assign.table.trip_no"),
          class: "text-center",
          align: "center",
          value: "tripNumber",
          urlFilter: "Travels/GetTripNumber",
          vmodel: "TripNumber",
          name: "TripNumber",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.amount"),
          class: "text-center",
          align: "center",
          value: "amount",
          urlFilter: "Travels/GetAmount",
          vmodel:"Amount",
          name:"Amount",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.type_operation"),
          class: "text-center",
          align: "center",
          value: "nameTypeOperation",
          urlFilter: "Travels/GetTypeOperation",
          vmodel:"TypeOperations",
          name:"TypeOperations",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.origin_city"),
          class: "text-center",
          align: "center",
          value: "origin.city",
          urlFilter: "Travels/GetOriginCityName",
          vmodel:"OriginCities",
          name:"OriginCities",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.origin_address"),
          class: "text-center",
          align: "center",
          value: "origin.address",
          urlFilter: "Travels/GetOriginAddress",
          vmodel:"OriginAddress",
          name:"OriginAddress",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.origin_date"),
          class: "text-center",
          align: "center",
          value: "origin.schedule_date",
          urlFilter: "Travels/GetOriginDate",
          vmodel:"OriginDate",
          name:"OriginDate",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.destiny_city"),
          class: "text-center",
          align: "center",
          value: "destiny.city",
          urlFilter: "Travels/GetDestinyCityName",
          vmodel:"DestiniesCities",
          name:"DestiniesCities",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.destiny_address"),
          class: "text-center",
          align: "center",
          value: "destiny.address",
          urlFilter: "Travels/GetDestinyAddress",
          vmodel:"DestiniesAddress",
          name:"DestiniesAddress",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.destiny_date"),
          class: "text-center",
          align: "center",
          value: "destiny.schedule_date",
          urlFilter: "Travels/GetDestinyDate",
          vmodel:"DestiniesDate",
          name:"DestiniesDate",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.no_offer_assign.table.number_stops"),
          class: "text-center",
          align: "center",
          value: "stopsCount",
          urlFilter: "Travels/GetStopCount",
          vmodel:"CountStops",
          name:"CountStops",
          showFilter: false,
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.not_offer_assigned"),
    };
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },

  },
  methods: {
    onRealoadTable() {
      this.dialogOfferTrips = false;
      this.dialogAssign = false;
      this.dialogOffersMassive = false
      this.$refs.NoAssignTable.loadData();
      this.selected = []
    },

    onOpenModalAssign(item) {
      this.dialogAssign = true;
      this.$nextTick(() => {
        this.index += 1;
        this.idMonitoringOrder = item.idMonitoringOrder;
      });
    },

    onOpenModalOfferTrips(item) {
      this.dialogOfferTrips = true;
      this.$nextTick(() => {
        this.index += 1;
        this.idMonitoringOrder = item.idMonitoringOrder;
      });
    },

    onOpenModalOffersMassive() {
      this.dialogOffersMassive = true;
      this.$nextTick(() => {
        this.index += 1;
      });
    },

    async onCloseModal() {
      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.no_offer_assign.table.cancel_the_process"),
          this.$t("components.views.trip.no_offer_assign.table.are_you_sure_to_cancel_the_process"),
          "aceptar"
        )
      ) {
        this.dialogOfferTrips = false;
        this.dialogAssign = false;
        this.dialogOffersMassive = false;

      }
    },
  },
};
</script>



<style scoped>
@import './TableStyle.scss';

.btn-massive-offer {
    color: #fff;
    margin-bottom: 10px;
    position: absolute;
    top:60px;
    margin-left: 0px;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 26px;
    min-width: 64px;
    padding: 0 10px;
    border-radius: 12px;
    border-radius: 4px !important;
    font-size: 14px;
    text-transform: capitalize;
}

.v-application .px-4 {
    padding-right: 25px !important;
    padding-left: 20px !important;
}

#status_offers[data-v-73e96722] #virtual-scroll > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.4em !important;
}

</style>
